import { loginUser } from '@/api/user';
import VueCookies from "vue-cookies";
const userStore = {
	namespaced: true,
	state: {
		id: VueCookies.get("id") || "",
		ndbTkn: VueCookies.get("ndbTkn") || "",
	},
	getters: {
		isLogin(state) {
			return state.ndbTkn !== "";
		},
		getId(state) {
			return state.id;
		},
		getNdbTkn(state) {
			return state.ndbTkn;
		},

	},
	mutations: {
		SET_ID(state, id) {
			state.id = id;
		},

		SET_NDB_TKN(state, ndbTkn) {
			state.ndbTkn = ndbTkn;
		},
		CLEAR_ALL(state) {
			state.id = "";
			state.ndbTkn = "";
		},
	},
	actions: {
		async LOGIN({ commit }, loginUserData) {
			const { data } = await loginUser(loginUserData);
			commit("SET_ID", data.data.id);
			commit("SET_NDB_TKN", data.data.ndbTkn);
			VueCookies.set("id", data.data.id);
			VueCookies.set("ndbTkn", data.data.ndbTkn);
		},
		LOGOUT({ commit }) {
			commit("CLEAR_ALL");
			VueCookies.remove("id");
			VueCookies.remove("ndbTkn");
		},
		REFRESH_USER({ commit }, data) {
			commit("SET_NDB_TKN", data.data.ndbTkn);
			VueCookies.set("ndbTkn", data.data.ndbTkn);
		}
	},
};

export default userStore;
