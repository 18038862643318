import { createRouter, createWebHistory } from "vue-router";
import store from "@/store/index";
import Swal from "sweetalert2";

const routes = [
  {
    path: "/",
    component: () => import("@/views/MainPage.vue"),
  },
  {
    path: "/login",
    component: () => import("@/views/LoginPage.vue"),
  },
  {
    path: "/sign-up",
    component: () => import("@/views/SignUpPage.vue"),
  },
  {
    path: "/find-users",
    component: () => import("@/views/FindIdPwdPopupPage.vue"),
    name: "FindIdPwd"
  },
  {
    path: "/my",
    name: "MyPage",
    component: () => import("@/views/MyPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/reports",
    component: () => import("@/views/ReportPage.vue"),
  },
  {
    path: "/reports/pay",
    component: () => import("@/views/ReportPayPage.vue"),
  },
  {
    path: "/reports-apply",
    component: () => import("@/views/ReportApplyPage.vue"),
  },
  {
    path: "/report-apply/results",
    component: () => import("@/views/ReportApplyResultPage.vue"),
  },
  {
    path: "/introduction",
    component: () => import("@/views/IntroductionPage.vue"),
  },
  {
    path: "/faq",
    component: () => import("@/views/FaqPage.vue"),
  },
  {
    path: "/questions",
    component: () => import("@/views/QuestionPage.vue"),
    meta: { authRequired: true },
  },
  {
    path: "/notices",
    component: () => import("@/views/NoticePage.vue"),
  },
  {
    path: "/notices/:sNo",
    component: () => import("@/views/NoticePopupPage.vue"),
    props: true,
    name: "NoticePopup"
  },
  {
    path: "/invoices/:regNo",
    component: () => import("@/views/InvoicePopupPage.vue"),
    props: true,
    meta: { authRequired: true },
    name: "InvoicePopup"
  },
  {
    path: "/contracts/:regNo",
    component: () => import("@/views/ContractPopupPage.vue"),
    props: true,
    meta: { authRequired: true },
    name: "ContractPopup"
  },
  {
    path: "/404",
    name: "404",
    component: () => import("@/views/NotFoundPage.vue"),
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: "/404",
  },
];



const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(routeInfo => routeInfo.meta.authRequired)) {
    const isLogin = store.getters["userStore/isLogin"];
    const lang = store.getters["commonStore/lang"];
    let alertTitle = "";
    let alertMsg = "";
    if (lang === 'ko') {
      alertTitle = "알림";
      alertMsg = "로그인 후 이용해 주세요."
    } else {
      alertTitle = "Notification";
      alertMsg = "Please login to use this feature.";
    }
    if (!isLogin) {
      Swal.fire({
        position: "center",
        html: ` <div class="modal-alert__wrap">
                  <div class="modal-alert__head">
                    <h1 class="modal-alert__title"  style="font-size: 24px">${alertTitle}</h1>
                  </div>
                  <div class="modal-alert__body">
                    <p class="modal-alert__msg"  style="font-size: 16px">${alertMsg}</p>
                  </div>
                </div>`,
        showConfirmButton: false,
        timer: 1000,
      });
      next("/login");
    } else {
      next();
    }
  } else {
    next();
  }
});
export default router;
