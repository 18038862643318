<template>
  <div>
    <div id="footer">
      <div id="footerin">
        <div id="footerin01">
          <h1 class="fl r38">
            <img :src="jodalLogoPath" alt="조달청" />
          </h1>
          <div class="t22" style="float: left; width: 700px">
            <div class="afl">
              <template v-if="lang === 'ko'">
                <div class="company-link">
                  <a
                    @click="
                      popupOpenNew(
                        'https://global.nicednb.com/aboutus1.do',
                        'info1',
                        '707',
                        '900',
                        '0'
                      )
                    "
                    >회사소개 </a
                  ><span> &nbsp;| &nbsp;</span>
                  <a
                    @click="
                      popupOpenNew(
                        'http://www.nicednb.com/etc_pop/privacy_pop.html',
                        'info2',
                        '900',
                        '900',
                        '0'
                      )
                    "
                    style="color: #fd5308"
                    >개인정보처리방침 </a
                  ><span> &nbsp;| &nbsp; </span>
                  <a
                    @click="
                      popupOpenNew(
                        'http://www.nicednb.com/etc_pop/credit_pop.html',
                        'info2',
                        '900',
                        '900',
                        '0'
                      )
                    "
                    >신용정보활용체제공시 </a
                  ><span> &nbsp;| &nbsp; </span>
                  <a
                    @click="
                      popupOpenNew(
                        'http://www.nicednb.com/etc_pop/email_pop.html',
                        '680',
                        '650'
                      )
                    "
                    >이메일무단수집거부
                  </a>
                </div>
              </template>
              <template v-else>
                <div class="footer_link">
                  <a
                    @click="
                      popupOpenNew(
                        'https://global.nicednb.com/aboutus1.do',
                        'info1',
                        '707',
                        '900',
                        '0'
                      )
                    "
                    >Company Profile </a
                  ><span> &nbsp;| &nbsp; </span>
                  <a
                    @click="
                      popupOpenNew(
                        'http://www.nicednb.com/etc_pop/privacy_pop.html',
                        'info2',
                        '900',
                        '900',
                        '0'
                      )
                    "
                    style="color: #fd5308"
                    >Personal information collection and use
                  </a>
                </div>
              </template>
              <br />
            </div>
            <address>
              <img
                :src="btm06Path"
                alt="서울시특별시 마포구 아현동 686 아크레디트센터  TEL : 02-2122-2500  FAX : 02-2122-2591  E-MAIL : nicednb@nicednb.com
						Copyright 2013 NICE DNB All right reserved."
              />
            </address>
          </div>
          <!-- <template v-if="lang == 'ko'">
            <div style="float: right; margin-top: 25px">
              <img
                :src="ismsLogo2Path"
                alt="ISMS인증취득, 인증범위:기업신용정보 서비스등 전 사업부문, 유효기간:2019년11월28일~2022년11월27일"
              />
            </div>
          </template> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    jodalLogoPath() {
      return require(`@/assets/images/${this.lang}/index/logo01.jpg`);
    },
    btm06Path() {
      return require(`@/assets/images/${this.lang}/index/btm06.jpg`);
    },
    ismsLogo2Path() {
      return require(`@/assets/images/${this.lang}/index/isms_logo2.jpg`);
    },
    ...mapGetters("userStore", ["isLogin"]),
    ...mapState("commonStore", ["lang"]),
  },

  mounted() {},
  methods: {
    popupOpenNew(name, idx, iwidth, iheight, left, top) {
      window.open(
        name,
        "",
        "scrollbars=1,width=" +
          iwidth +
          ",height=" +
          iheight +
          ",left=" +
          left +
          ",top=" +
          top
      );
    },
  },
};
</script>