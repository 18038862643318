<template>
  <div>
    <!-- [팝업] 비밀번호 체크 팝업 -->
    <div class="modal-popup modal-popup--small" id="modalUserPwdCheck">
      <div class="modal-popup__wrap">
        <div class="modal-popup__head">
          <h1 class="modal-popup__title"></h1>
        </div>
        <div class="modal-popup__body" style="max-height: 600px">
          <div class="modal-popup__content">
            <div class="com-notiBox">
              <h2>
                <template v-if="lang === 'ko'">
                  <strong
                    >사용자정보 확인을 위해 비밀번호를 입력하세요.
                  </strong>
                </template>
                <template v-else>
                  <strong
                    >Please enter your password to verify your user information.
                  </strong>
                </template>
              </h2>
            </div>
            <form @submit.prevent="userCheck">
              <div class="com-login">
                <div class="com-login__inputArea">
                  <input
                    type="password"
                    class="input-pwd"
                    placeholder="비밀번호"
                    title="비밀번호"
                    v-model="inpPwd"
                    maxlength="16"
                    autocomplete="new-password"
                  />
                </div>

                <div class="btn-wrap">
                  <button type="button" class="btn-pri" @click="userCheck">
                    확인
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>

        <div class="modal-popup__close" @click="popupClose">
          <button type="button"><span class="blind"></span></button>
        </div>
      </div>
    </div>
    <!-- //[팝업]캐시충전 -->
    <ModalAlert ref="ModalAlert" />
    <ModalConfirm ref="ModalConfirm" />
  </div>
</template>


<script>
import { mapState, mapGetters } from "vuex";
import ModalAlert from "@/components/common/ModalAlert.vue";
import ModalConfirm from "@/components/common/ModalConfirm.vue";
import { userPwdCheck, withdraw } from "@/api/user";
export default {
  components: { ModalAlert, ModalConfirm },
  props: {
    isUserPwdShow: Boolean,
  },
  data: () => ({
    inpPwd: "",
  }),

  computed: {
    ...mapGetters("userStore", ["isLogin"]),
    ...mapState("commonStore", ["lang"]),
  },
  mounted() {
    this.modalPopupOpen("modalUserPwdCheck");
  },

  methods: {
    async userCheck() {
      if (!this.inpPwd) {
        this.callAlert("비밀번호를 입력해주세요.");
        return;
      }

      try {
        const userPwdCheckData = {
          password: btoa(this.inpPwd + "@#"),
        };
        const { data } = await userPwdCheck(userPwdCheckData);
        if (data.message === "Success") {
          this.userWithdraw();
        }
      } catch (error) {
        this.$refs.ModalAlert.error(error);
      }
    },

    callAlert(message) {
      const flag = this.$refs.ModalAlert.show({
        title: "확인",
        content: `${message}`,
      });
    },

    //레어어팝업 열기
    modalPopupOpen(popUpID) {
      let openPopup = document.getElementById(popUpID);
      openPopup.classList.add("modal-popup--show");
      openPopup.setAttribute("tabindex", "0");
      openPopup.focus();
      document.body.style.overflow = "hidden";
    },
    popupClose() {
      this.modalPopupClose("modalUserPwdCheck");
      this.$emit("isUserPwdShow", false);
    },
    //레이어팝업 닫기
    modalPopupClose(popUpID) {
      let closePopup = document.getElementById(popUpID);
      closePopup.classList.remove("modal-popup--show");
      closePopup.removeAttribute("tabindex");
      document.body.removeAttribute("style");
    },

    userWithdraw() {
      let title = "확인";
      let content =
        "회원탈퇴 후 복구가 불가능합니다.<br/>회원탈퇴를 진행하시겠습니까?";
      let confirmButtonText = "확인";
      let cancelButtonText = "취소";
      let withdrawSuccess = "회원탈퇴가 완료되었습니다.";
      if (this.lang === "en") {
        title = "Confirm";
        content =
          "Recovering an account<br/>is not possible after withdrawal.<br/>Are you sure you want to proceed with withdrawal?";
        confirmButtonText = "Confirm";
        cancelButtonText = "Cancel";
        withdrawSuccess = "Your membership has been successfully withdrawn.";
      }
      const flag = this.$refs.ModalConfirm.show({
        title: title,
        content: content,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
      }).then((result) => {
        if (result) {
          try {
            const { data } = withdraw();
            this.callAlertSub(withdrawSuccess);
            this.popupClose();
            this.withdrawNext();
          } catch (err) {
            this.callAlertSub(err.response.data.message);
          }
        }
      });
    },
    withdrawNext() {
      this.$router.push("/login");
    },
    callAlertSub(message) {
      const flag = this.$refs.ModalAlert.show({
        title: "확인",
        content: `${message}`,
      });
    },
  },
};
</script>