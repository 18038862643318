<template>
  <div>
    <HeaderBar />
  </div>
</template>

<script>
import HeaderBar from "@/components/common/HeaderBar.vue";
export default {
  components: {
    HeaderBar,
  },
};
</script>
