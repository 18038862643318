<template>
  <div v-if="isVisable">
    <slot></slot>
  </div>
</template>

<script>
export default {
  data: () => ({
    isVisable: false,
  }),
  methods: {
    open() {
      this.isVisable = true;
    },
    close() {
      this.isVisable = false;
    },
  },
};
</script>

<style lang="scss" scoped>
</style>