import store from "@/store/index";
import Swal from "sweetalert2";
import router from "@/router";
import { refreshUser } from "@/api/user";

let isTokenRefreshing = false;
let refreshSubscribers = [];

// 리프레시 후, 대기 중인 요청을 재시도하기 위한 콜백 실행
const onTokenRefreshed = (ndbTkn) => {
  refreshSubscribers.forEach((callback) => callback(ndbTkn));
  refreshSubscribers = [];
};

// 토큰 갱신이 완료되면 재시도할 요청을 등록
const addRefreshSubscriber = (callback) => {
  refreshSubscribers.push(callback);
};

export function setInterceptors(instance) {
  instance.interceptors.request.use(
    (config) => {
      const ndbTkn = store.getters["userStore/getNdbTkn"];
      if (ndbTkn) {
        config.headers.Authorization = `Bearer ${ndbTkn}`;
      }
      return config;
    },
    (error) => Promise.reject(error)
  );

  instance.interceptors.response.use(

    (response) => response,
    async (error) => {
      const {
        config,
        response: { status },
      } = error;
      const originalRequest = config;
      if (status === 401 && error.response.data.code === "A003") {
        if (!isTokenRefreshing) {
          isTokenRefreshing = true;
          try {
            const orginTkn = store.getters["userStore/getNdbTkn"];
            const param = { ndbTkn: orginTkn };
            const renewToken = await refreshUser(param);
            store.dispatch("userStore/REFRESH_USER", renewToken.data);
          } catch (refreshError) {
            onTokenRefreshed(null); // 대기 중인 요청에 에러 전달
            const lang = store.getters["commonStore/lang"];
            Swal.fire({
              position: "center",
              html: ` <div class="modal-alert__wrap">
                        <div class="modal-alert__head">
                          <h1 class="modal-alert__title" style="font-size: 24px">알림</h1>
                        </div>
                        <div class="modal-alert__body">
                          <p class="modal-alert__msg" style="font-size: 16px">${error.response.data.message}</p>
                        </div>
                      </div>`,
              showConfirmButton: false,
              timer: 2500,
            });
            store.dispatch("userStore/LOGOUT");
            router.push("/login");
            return Promise.reject(refreshError);
          } finally {
            isTokenRefreshing = false;
          }
        }
        // 기존 요청을 재시도
        return new Promise((resolve) => {
          addRefreshSubscriber((ndbTkn) => {
            originalRequest.headers.Authorization = `Bearer ${ndbTkn}`;
            resolve(instance(originalRequest)); // 인스턴스를 사용하여 기존 요청 재시도
          });
          onTokenRefreshed();
        });
      }
      else if (status === 401 && error.response.data.code === "A004") {
        Swal.fire({
          position: "center",
          html: ` <div class="modal-alert__wrap">
                    <div class="modal-alert__head">
                      <h1 class="modal-alert__title">알림</h1>
                    </div>
                    <div class="modal-alert__body">
                      <p class="modal-alert__msg">${error.response.data.message}</p>
                    </div>
                  </div>`,
          showConfirmButton: false,
          timer: 2500,
        });
        store.dispatch("userStore/LOGOUT");
        if (router.path !== "/login") {
          router.push("/login");
        }
      }
      return Promise.reject(error);
    }
  );

  return instance;
}