<template>
  <div id="app">
    <div id="bg">
      <div id="container">
        <Header v-if="this.mountHeader" />
        <div class="app__contents">
          <RouterView />
        </div>
      </div>
    </div>
    <Footer v-if="this.mountFooter" />
  </div>
</template>

<script>
import Header from "@/components/common/Header.vue";
import Footer from "@/components/common/Footer.vue";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  computed: {
    mountHeader() {
      return (
        this.$route.name !== "404" &&
        this.$route.name !== "NoticePopup" &&
        this.$route.name !== "InvoicePopup" &&
        this.$route.name !== "FindIdPwd" &&
        this.$route.name !== "FindIdPwdEn" &&
        this.$route.name !== "ContractPopup"
      );
    },
    mountFooter() {
      return (
        this.$route.name !== "404" &&
        this.$route.name !== "NoticePopup" &&
        this.$route.name !== "InvoicePopup" &&
        this.$route.name !== "FindIdPwd" &&
        this.$route.name !== "FindIdPwdEn" &&
        this.$route.name !== "ContractPopup"
      );
    },
  },
};
</script>
<style scoped>
@import "@/assets/css/ko/layout.css";
@import "@/assets/css/ko/bbs.css";
@import "@/assets/css/ko/style.css";
@import "@/assets/css/en/layout.css";
@import "@/assets/css/en/bbs.css";
@import "@/assets/css/en/style.css";
@import "@/assets/css/common.css";
@import "@/assets/css/font_kr.css";
@import "@/assets/css/fg.menu.css";
@import "@/assets/css/ddsmoothmenu.css";
@import "@/assets/css/webwidget_vertical_menu.css";
</style>

