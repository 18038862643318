import { instance } from '@/api/index';

function noticeMain() {
	return instance.get('/notices/main');
}
function noticeDetail(noticeId) {
	return instance.get(`/notices/${noticeId}`);
}
function noticeList(param) {
	return instance.get('/notices', { params: param });
}

export { noticeMain, noticeDetail, noticeList };
