<template>
  <div id="header">
    <h1>
      <RouterLink to="/"><img :src="logoPath" alt="HOME" /> </RouterLink>
    </h1>
    <ul class="gnb afl">
      <li>
        <RouterLink to="/"><img :src="homePath" alt="HOME" /></RouterLink>
      </li>
      <li>
        <a @click="goLangKo"><img :src="goLangKoPath" alt="" /></a>
      </li>
      <li>
        <a @click="goLangEn"><img :src="goLangEnPath" alt="" /></a>
      </li>
    </ul>
    <ul class="menu afl">
      <template v-if="isLogin">
        <li @click="userLogout">
          <img :src="logoutPath" alt="로그아웃" />
        </li>
        <li>
          <a @click="userPwdToggleShow"
            ><img :src="withDrawlPath" alt="회원탈퇴"
          /></a>
        </li>
      </template>
      <template v-else>
        <li>
          <RouterLink to="/login">
            <img :src="loginPath" alt="로그인" />
          </RouterLink>
        </li>
      </template>
      <li>
        <RouterLink to="/introduction"
          ><img :src="servicePath" alt="서비스 소개"
        /></RouterLink>
      </li>
      <li>
        <RouterLink to="/reports"
          ><img :src="reportPath" alt="D B 보고서"
        /></RouterLink>
      </li>
      <li>
        <RouterLink to="/my"
          ><img :src="mypagePath" alt="마이 페이지"
        /></RouterLink>
      </li>
    </ul>
  </div>
  <div v-if="isUserPwdShow == true">
    <UserPwdCheckPopup
      :isUserPwdShow="isUserPwdShow"
      @isUserPwdShow="userPwdToggle"
    />
  </div>
  <ModalAlert ref="ModalAlert" />
  <ModalConfirm ref="ModalConfirm" />
</template>

<script>
import { mapState, mapGetters } from "vuex";
import UserPwdCheckPopup from "@/components/user/UserPwdCheckPopup.vue";
import ModalAlert from "@/components/common/ModalAlert.vue";
import ModalConfirm from "@/components/common/ModalConfirm.vue";
export default {
  components: {
    UserPwdCheckPopup,
    ModalAlert,
    ModalConfirm,
  },
  data: () => ({
    isUserPwdShow: false,
  }),
  computed: {
    logoPath() {
      return require(`@/assets/images/${this.lang}/top/logo.jpg`);
    },
    homePath() {
      return require(`@/assets/images/${this.lang}/top/t_img01.jpg`);
    },
    goLangKoPath() {
      return require(`@/assets/images/${this.lang}/top/t_img02.jpg`);
    },
    goLangEnPath() {
      return require(`@/assets/images/${this.lang}/top/t_img03.jpg`);
    },
    logoutPath() {
      return require(`@/assets/images/${this.lang}/top/tmenu011.jpg`);
    },
    withDrawlPath() {
      return require(`@/assets/images/${this.lang}/top/tmenu06.jpg`);
    },
    loginPath() {
      return require(`@/assets/images/${this.lang}/top/tmenu01.jpg`);
    },
    servicePath() {
      return require(`@/assets/images/${this.lang}/top/tmenu02.jpg`);
    },
    reportPath() {
      return require(`@/assets/images/${this.lang}/top/tmenu04.jpg`);
    },
    mypagePath() {
      return require(`@/assets/images/${this.lang}/top/tmenu05.jpg`);
    },

    ...mapGetters("userStore", ["isLogin"]),
    ...mapState("commonStore", ["lang"]),
  },
  methods: {
    goLangKo() {
      this.$store.dispatch("commonStore/LANG_ACT", "ko");
    },
    goLangEn() {
      this.$store.dispatch("commonStore/LANG_ACT", "en");
    },
    userLogout() {
      let title = "확인";
      let content = "로그아웃 하시겠습니까?";
      let confirmButtonText = "확인";
      let cancelButtonText = "취소";
      let logoutSuccess = "로그아웃이 완료되었습니다.";
      if (this.lang === "en") {
        title = "Confirm";
        content = "Do you want to Log-out?";
        confirmButtonText = "Confirm";
        cancelButtonText = "Cancel";
        logoutSuccess = "Log-out Completed";
      }
      const flag = this.$refs.ModalConfirm.show({
        title: title,
        content: content,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
      }).then((result) => {
        if (result) {
          this.$store.dispatch("userStore/LOGOUT");
          this.$router.push("/login");
          this.callAlert(
            title,
            logoutSuccess,
            confirmButtonText,
            cancelButtonText
          );
        }
      });
    },

    userPwdToggleShow() {
      this.isUserPwdShow = !this.isUserPwdShow;
    },

    userPwdToggle(show) {
      this.isUserPwdShow = show;
    },

    callAlert(title, logoutSuccess, confirmButtonText, cancelButtonText) {
      this.$refs.ModalAlert.show({
        title: title,
        content: logoutSuccess,
        confirmButtonText: confirmButtonText,
        cancelButtonText: cancelButtonText,
      });
    },


  },
};
</script>