import { instance, instanceWithAuth } from '@/api/index';

function loginUser(userData) {
	return instance.post('/auth/login', userData);
}
function refreshUser(param) {
	return instance.get("/auth/refresh", { params: param });

}
function idCheck(param) {
	return instance.get("/users/id-check", { params: param });
}
function signUp(signUpData) {
	return instance.post("/users/sign-up", signUpData);
}
function profile() {
	return instanceWithAuth.get("/users");
}
function withdraw() {
	return instanceWithAuth.post("/users/withdraw");
}
function findId(findIdReq) {
	return instance.post("/users/find-id", findIdReq);
}
function findPwd(findPwdReq) {
	return instance.post("/users/find-pwd", findPwdReq);
}
function userPwdCheck(pwdCheck) {
	return instanceWithAuth.post("/users/pwd-check", pwdCheck);
}
export { loginUser, refreshUser, idCheck, signUp, profile, withdraw, findId, findPwd, userPwdCheck };
