import { noticeList, noticeMain } from '@/api/notice';
import Swal from "sweetalert2";
const noticeStore = {
	namespaced: true,
	state: {
		noticeList: [],
		noticeMain: [],
	},
	getters: {
	},
	mutations: {
		SET_NOTICE_LIST(state, noticeList) {
			state.noticeList = noticeList;
		},

		SET_NOTICE_MAIN(state, noticeMain) {
			state.noticeMain = noticeMain;
		},
	},
	actions: {
		async NOTICE_LIST({ commit }, param) {
			try {
				const { data } = await noticeList(param);
				commit("SET_NOTICE_LIST", data.data.noticeList);
			} catch (err) {
				let content = "데이터를 불러오는 중 오류가 발생하였습니다.";
				if (err.response != undefined) {
					content = err.response.data.message;
				}
				Swal.fire({
					position: "center",
					html: ` <div class="modal-alert__wrap">
								<div class="modal-alert__head">
								<h1 class="modal-alert__title">알림</h1>
								</div>
								<div class="modal-alert__body">
								<p class="modal-alert__msg">${content}</p>
								</div>
							</div>`,
					showConfirmButton: false,
					timer: 2500,
				});
			}
		},
		async NOTICE_MAIN({ state, commit }) {
			if (state.noticeMain.length == 0) {
				try {
					const { data } = await noticeMain();
					commit("SET_NOTICE_MAIN", data.data.noticeList);
				} catch (err) {
					let content = "데이터를 불러오는 중 오류가 발생하였습니다.";
					if (err.response != undefined) {
						content = err.response.data.message;
					}
					Swal.fire({
						position: "center",
						html: ` <div class="modal-alert__wrap">
								<div class="modal-alert__head">
								<h1 class="modal-alert__title">알림</h1>
								</div>
								<div class="modal-alert__body">
								<p class="modal-alert__msg">${content}</p>
								</div>
							</div>`,
						showConfirmButton: false,
						timer: 2500,
					});
				}
			}
		},
	},
};

export default noticeStore;
