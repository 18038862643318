import { createStore } from "vuex";
import userStore from '@/store/modules/userStore';
import commonStore from "@/store/modules/commonStore";
import noticeStore from "@/store/modules/noticeStore";
export default createStore({
  modules: {
    userStore,
    commonStore,
    noticeStore,
  },
});