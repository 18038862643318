import { getCountryCodes } from "@/api/common";
import VueCookies from "vue-cookies";
import Swal from "sweetalert2";
const commonStore = {
    namespaced: true,
    state: {
        lang: VueCookies.get("lang") || "ko",
        countryCodes: [],
    },
    getters: {
        lang(state) {
            return state.lang;
        },
        countryCodes(state) {
            return state.countryCodes;
        },
    },
    mutations: {
        SET_LANG(state, lang) {
            state.lang = lang;
        },
        SET_COUNTRY_CODES(state, countryCodeList) {
            state.countryCodes.push(...countryCodeList);
        },
    },
    actions: {
        LANG_ACT({ commit }, lang) {
            commit("SET_LANG", lang);
            VueCookies.set("lang", lang);
        },
        async GET_COUNTRY_CODES({ state, commit }) {
            if (state.countryCodes.length == 0) {
                try {
                    const { data } = await getCountryCodes();
                    commit("SET_COUNTRY_CODES", data.data);
                } catch (err) {
                    let content = "국가코드를 불러오는 중 오류가 발생하였습니다.";
                    if (err.response != undefined) {
                        content = err.response.data.message;
                    }
                    Swal.fire({
                        position: "center",
                        html: ` <div class="modal-alert__wrap">
                                    <div class="modal-alert__head">
                                    <h1 class="modal-alert__title">알림</h1>
                                    </div>
                                    <div class="modal-alert__body">
                                    <p class="modal-alert__msg">${content}</p>
                                    </div>
                                </div>`,
                        showConfirmButton: false,
                        timer: 2500,
                    });
                }
            }
        },
    }
}

export default commonStore;